import React, { Component } from 'react';
import Modal from 'react-modal';
import { SocialIcon } from 'react-social-icons';
import ReactGA from 'react-ga';

import projects from './projects'
import './App.css';

const colors = [
  '#90A5B3',
  '#EE8E54',
  '#C5484B',
  '#3D3F54'
]

ReactGA.initialize('UA-52688137-1');

class App extends Component {
  state = {
    showModal: false,
    selectedProject: null
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  toggleModal = project => {
    if (project) {
      ReactGA.modalview(project.name);
      this.setState({ showModal: !this.state.showModal, selectedProject: project })
    } else {
      this.setState({ showModal: !this.state.showModal })
    }
  }

  render() {
    const { showModal, selectedProject } = this.state
    let colorIndex = 0
    const portfolio = projects.apps.map(p => {
      const color = colors[colorIndex]
      let style = {}
      if (p.image) {
        style = { backgroundImage: `url(${p.image})` }
      } else {
        style = { backgroundColor: color }
        colorIndex = colorIndex < colors.length - 1 ? colorIndex + 1 : 0
      }
      return <div className="project" key={p.id} style={style} onClick={() => this.toggleModal(p)}>
        {!p.image && <h2>{p.name}</h2>}
      </div>
    })

    const involvement = projects.activities.map(p => {
      const color = colors[colorIndex]
      let style = {}
      if (p.image) {
        style = { backgroundImage: `url(${p.image})` }
      } else {
        style = { backgroundColor: color }
        colorIndex = colorIndex < colors.length - 1 ? colorIndex + 1 : 0
      }
      return <div className="project" key={p.id} style={style} onClick={() => this.toggleModal(p)}>
        {!p.image && <h2>{p.name}</h2>}
      </div>
    })

    const stack = selectedProject && selectedProject.stack ? selectedProject.stack.map(sp => {
      return <p>{sp}</p>
    }) : '';

    const customModalStyle = {
      content : {
        maxWidth: '500px',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        padding: '50px',
      }
    };

    return (
      <div className="container">
        <div className="header">
          <h1>Hi, I'm Brent Bovenzi</h1>
          <div className="profile" />
          <h3>Full-stack engineer for hire</h3>
          <p>I work with startups to bring their ideas to production-grade MVPs</p>
          <p>I mainly work in React, React Native, and Node.js.</p>
        </div>
        <div className="icon-row">
          <SocialIcon url="https://www.linkedin.com/in/brent-bovenzi/" />
          <SocialIcon url="https://github.com/bbovenzi" />
          <SocialIcon url="mailto:brent.bovenzi@gmail.com" />
        </div>
        <h2 className="portfolio-header">Portfolio</h2>
        <div className="portfolio">
          {portfolio}
        </div>
        <h2 className="portfolio-header">Other involvements</h2>
        <div className="portfolio">
          {involvement}
        </div>
        {selectedProject && <Modal
          style={customModalStyle}
          isOpen={showModal}
          onRequestClose={this.toggleModal}>
          <p onClick={this.toggleModal} className="cancel">x</p>
          <div className="modal-content">
            <div className="modal-header">
              <div className="project-badge" style={{ backgroundImage: `url(${selectedProject.image})` }} />
              <h1>{selectedProject.name}</h1>
            </div>
            <p>{selectedProject.description}</p>
            {selectedProject.stack && <div>
              <h3>Stack</h3>
              {stack}
            </div>}
            <h3>Website</h3>
            <a href={selectedProject.url} target="_blank" rel="noopener noreferrer">{selectedProject.name}</a>
          </div>
        </Modal>}
      </div>
    );
  }
}

export default App;
